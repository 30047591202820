import React, {useContext, useEffect} from "react"
import { Row, Col, Form, Alert } from "react-bootstrap"
import PlanAttribute from "../PlanAttribute"
import { AppContext } from "../../contexts/global.context"
import {updateSupportDisplay} from '../../contexts/actions'

function PaymentInfoForm({ formik, selectedPlan }) {
  const {dispatch } = useContext(AppContext);

  useEffect(()=> {
    dispatch(updateSupportDisplay(false))
  }, [])
  return (
    <>
      <h1 className="tw-ui-sans-serif tw-text-3xl tw-font-bold tw-text-center">
        Checkout
      </h1>
      <Row className="align-col-center padding-for-bigger-screens">
        <Col className="tw-divide-y tw-divide-dashed tw-divide-y-reverse">
          <h2 className="tw-ui-sans-serif  tw-my-4 tw-font-bold tw-text-lg">
            Internet Plan Summary
          </h2>
          <div className="tw-divide-y tw-divide-dashed">
            <PlanAttribute name={'Name'} value={selectedPlan?.name} />
            <PlanAttribute name={'Duration'} value={`${selectedPlan?.duration} ${selectedPlan?.time_unit}`} />
            <PlanAttribute name={'Upload Speed'} value={`${selectedPlan?.upload_speed} ${selectedPlan?.speed_unit}`} />
            <PlanAttribute name={'Download Speed'} value={`${selectedPlan?.download_speed} ${selectedPlan?.speed_unit}`} />
            <PlanAttribute name={'Price'} value={`${selectedPlan?.currency} ${selectedPlan?.price}`} />
          </div>
        </Col>
      </Row>
      <Row className="align-col-center padding-for-bigger-screens">
        <Col>
          <h2 className="tw-ui-sans-serif tw-my-4 tw-font-bold tw-text-lg">
            How to Make Payment
          </h2>
          <Alert variant="warning">
            <Row>
              <Col>
                <ul className="tw-list-decimal tw-ml-3">
                  <li>Enter you Mpesa Mobile Number in the input below</li>
                  <li>Click Pay Now Button</li>
                  <li>STK push will pop up on your Phone. Please confirm amount and enter your PIN</li>
                  <li>Confirm payment in the next screen</li>
                </ul>
              </Col>
            </Row>
          </Alert>
        </Col>
      </Row>
      <Row className="align-col-center padding-for-bigger-screens">
        <Col>
          <h2 className="tw-ui-sans-serif tw-my-4 tw-font-bold tw-text-lg">
            Payment Method (MPESA)
          </h2>
          <Form.Group className="mb-3" controlId="phoneNumber">
            <Form.Label>Phone Number</Form.Label>

            <Form.Control
              type="text"
              placeholder="0xxxxxxxx"
              name="payment.mpesaPhoneNumber"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.payment.mpesaPhoneNumber}
            />
            {formik.errors.payment && (
              <Form.Text className="text-danger">
                {formik.errors.payment.mpesaPhoneNumber}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default PaymentInfoForm
